import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/_debugger.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/cookieHint/cookieHint.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainProviders"] */ "/app/component/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/lib/polyfills.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["StoreInitializer","DeviceInitializer","CookieConsentInitializer"] */ "/app/store/server-context/store-initializer.ts");
